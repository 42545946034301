.sub_container {
    padding: 0 30px;
    margin: 0 auto;
    border-radius: 5px;
    text-align: left;
}

.sub_title {
    font-size: xx-large;
    text-align: left;
    margin: 10px 0;
}

.sub_formlabel {
    margin: 10px 0px;
    padding: 10px 0px;
    width: 25%;
    display: inline-block;
}

.sub_formelement {
    width: 90%;
    height: 40px;
    border: 1px dotted gray;
}

.buttoncontainer {
    width: 100%;
    text-align: center;
    margin: 20px;
}

.sub_submit {
    width: 300px;
    height: 40px;
    border: 0px;
    border-radius: 5px;
    font-size: medium;
    background-color: red;
    color: white;
    font: bold;
}

.sub_loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}