.home_outercontainer {
    width: 100%;
    text-align: center;
}

.home_innercontainer {
    padding: 30px;
    margin: 0;
}

.home_title {
    font-size: xx-large;
    text-align: left;
    margin: 0 0 30px; 
}

.home_videocontainer {
    display: flex;
    justify-content:first baseline;
    align-items: start;
}

.home_sidetitle {
    margin: 0 10px;
    padding: 0 75px;
    font-size: x-large;
    text-align: left;
}

.home_copyright {
    text-align: left;
    padding: 0 30px;
}
