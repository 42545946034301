/* Modal {
    position: fixed;
    top: 25%;
    left: 25%;
    right: auto;
    width: 40%;
    bottom: auto;
} */

.cer_title {
    font-size: xx-large;
    text-align: center;
    margin: 15px;
    padding: 15px;
}

.cer_formcontainer {
    display: flex;
    align-items: center;
    justify-content:flex-start;
    margin-top: 15px;
}

.cer_formlabel {
    width: 15%;
    margin: 5px;
}

.cer_formelement {
    height: auto;
    border: 0px;
    padding-left: 25px;
    margin: 0;
}

.bold {
    font-size: larger;
    font-weight:600;
    width: 100%;
    padding-top: 50px;
    text-align: center;
}

.semibold {
    width: 100%;
    padding-top: 15px;
    text-align: center;
}

@media print {
    .cer_buttoncontainer {
      display: none;
    }
}

.cer_buttoncontainer {
    margin-top: 45px;
    width: 100%;
    text-align: center;
}

.cer_print {
    width: 300px;
    height: 40px;
    border: 0px;
    border-radius: 5px;
    font-size: medium;
    background-color: red;
    color: white;
    font: bold;
    display: block;
    margin: 15px auto;
}