nav {
    background-color: red;
    height: 75px;
    width: 100%;
    position: fixed;
    overflow: hidden;
    top: 0;
    text-align: center;
}

img {
    display: block;
    margin: 0 auto;
    height: 60px;
    padding: 5px;
}