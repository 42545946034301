.rp_container {
    width: 90%;
    margin: 15px 30px;
}

.rp_title {
    font-size: xx-large;
    text-align: left;
    margin: 0;
    padding: 0;
}

.rp_subtitle {
    font-size: large;
    font-weight: 400;
    text-align: left;
    margin: 0;
    padding: 0;
}

.rp_form {
    width: 100%;
}

.rp_tablecontainer {
    width: 100%;
}

@media print {
    .rp_tablecontainer {
        display: none;
    }
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 90%;
    box-sizing: content-box;
}
td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.rp_formlabel {
    margin: 10px 0px;
    padding: 10px 0px;
    width: 30%;
    display: block;
}

.rp_formelement {
    width: 90%;
    height: 40px;
    border: 1px dotted gray;
}

.rp_buttoncontainer {
    width: 80%;
    text-align: center;
    margin: 25px 0 50px;
}

.rp_submit {
    width: 300px;
    height: 40px;
    border: 0px;
    border-radius: 5px;
    font-size: medium;
    background-color: red;
    color: white;
    font: bold;
}

/* tr:nth-child(even) {
    background-color: #dddddd;
} */

.rp_loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}